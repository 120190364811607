import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HomeViewDemo from '../views/HomeViewDemo.vue'
import EmptyView from '../views/404.vue'
import SearchView from '../views/searchView.vue'
import VipView from '../views/VipView.vue'
import DemoView from '../views/demo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/demo',
    name: 'demo',
    component: HomeViewDemo
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchView
  },
  {
    path: '/404',
    name: 'EmptyView',
    component: EmptyView
  },
  {
    path: '/svip',
    name: 'VipVue',
    component: VipView
  },
  {
    path: '/dingding',
    name: 'DemoView',
    component: DemoView
  },
  {
    path: '/article/:articleId',
    name: 'ArticleInfo',
    component: () => import('../views/article.vue'),
  },
  {
    path: '/dzaobao',
    name: 'ZaoBao',
    component: () => import('../views/zaobao.vue'),
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
